.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#canvas {
  width: 100%;
  height: 100%;
}

.loading {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
}

/* Intro screen */
.IntroScreenComponent {
  background-color: rgba(4, 25, 48, 0.7);
  border: 1px solid white;
  border-radius: 20px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 90vh;
  left: 0;
  margin-left: 10vw;
  margin-top: 5vh;
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 0;
  width: 80vw;
  z-index: 1000;
}

.IntroScreenComponentHeader {
  align-items: center;
  background-color: #09080faa;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 22px;
}

.IntroScreenComponentContent {
  flex: 1;
  overflow: hidden;
  padding: 10px;
  position: relative;
}

.IntroScreenComponentContent img {
  display: block !important;
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
  width: auto !important;
}

.IntroScreenComponentFooter {
  background-color: #09080faa;
  border-top: 1px solid white;
  color: #cbb15c;
  display: flex;
  font-weight: bold;
  justify-content: center;
  padding: 10px 30px;
}

.IntroScreenComponentFooter a:before {
  content: '';
  background-image: url('/public/images/icon-home.png');
  background-position: 10px 9px;
  background-repeat: no-repeat;
  background-size: 60%;
  display: block;
  background-color: rgba(116, 116, 116, 0.5);
  text-align: center;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto 5px auto;
  width: 50px;
  height: 50px;
}

.IntroScreenComponentFooter a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}

/* Info screen */
.InfoScreenComponentHeader {
  align-items: center;
  background-color: #09080faa;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 22px;
}

.InfoScreenComponentHeaderWrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 5;
  display: flex;

  justify-content: space-between;
}

.InfoScreenComponentFooter {
  background-color: #09080faa;
  border-top: 1px solid white;
  color: #cbb15c;
  display: flex;
  font-weight: bold;
  justify-content: center;
  padding: 10px 30px;
}

.InfoScreenComponent {
  align-items: center;
  bottom: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 100;
}

.InfoScreenComponent.relative {
  position: relative !important;
}

.InfoScreenComponentContent {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 80%;
  margin: 0;
  text-align: center;
  width: 100%;
  height: calc(100vh - 160px);
  border-radius: 20px;
  overflow: hidden;
}

.InfoScreenComponentContentHeader,
.InfoScreenComponentContentFooter {
  align-items: center;
  background-color: #09080faa;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 2rem;
  font-weight: 700;
  padding: 22px;
  min-height: 95px;
  width: 100%;
}

.InfoScreenComponentContentHeader {
  border-bottom: 1px solid white;
}

.InfoScreenComponentContentFooter {
  border-top: 1px solid white;
  padding: 10px 30px;
}

.InfoScreenComponentContentFooter {
  align-items: flex-end;
  justify-content: center;
}

.InfoScreenComponentContentFooter a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}

.InfoScreenComponentContentFooter a:before {
  content: '';
  background-image: url('/public/images/icon-home.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  display: block;
  background-color: rgba(116, 116, 116, 0.5);
  text-align: center;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto 5px auto;
  width: 50px;
  height: 50px;
}

.InfoScreenComponentContentFooter a.back:before {
  background-position: 9px 9px;
  transform: rotate(-90deg);
}

.InfoScreenComponentContentHeader h1 {
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0;
}

.InfoScreenComponentContentContent {
  box-sizing: border-box;
  color: #fff;
  flex: 1;;
  font-size: 1rem;
  font-weight: 400;
  padding: 30px 50px;
  width: 100%;
  overflow-y: scroll;
  text-align: left;
  position: relative;
}

.InfoScreenComponentContentContent img {
  border: 1px solid #fff;
  border-radius: 5px;
  max-width: 300px;
}

.InfoScreenComponentIcon {
  border-radius: 25px;
  height: 50px;
  margin-right: 10px;
  overflow: hidden;
  width: 50px;
}

.InfoScreenComponentIcon img {
  height: 100%;
}

/* Arteffact screen */
.ArteffactScreenComponent .ButtonBack {
  color: #fff;
  font-size: 10px;
  margin-right: 10px;
  text-decoration: none;
  text-align: center;
}

.ArteffactScreenComponent .ButtonBack:before {
  background-position: 8px 8px;
  height: 40px;
  margin-bottom: 3px;
  width: 40px;
}

.ArteffactScreenComponent fieldset.screen-wallpaper-fieldset {
  /* border: 2px solid #fff; */
  border: 0;
  width: calc(100% - 10px);
  height: calc(100% - 20px);
  padding: 0;
  margin: 0;
}

.ArteffactScreenComponent fieldset.screen-wallpaper-fieldset legend {
  display: flex;
  margin: 0 !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ArteffactScreenComponentTitleLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ArteffactScreenComponentTitleRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
}

.ArteffactScreenComponentTitleRightTitle {
  color: #3cabfa;
  font-size: 18px;
  padding-right: 10px;
}

.ArteffactScreenComponentTitleRightLogo img {
  max-height: 50px;
}

.ArteffactScreenComponentContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 100%;
  text-align: center;
  width: 100%;
  height: calc(100vh - 80px);
  border-radius: 20px;
  overflow: hidden;
}

.ArteffactScreenComponentContentContent {
  box-sizing: border-box;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  height: 70%;
  padding: 0 50px;
  width: 100%;
  overflow-y: scroll;
  text-align: left;
  position: relative;
}

.slick-slider, .slick-track, .slick-list, .slick-slide, .slick-slide > div {
  height: 100%;
}

.slick-list {
  padding-left: 5px;
}

.slick-prev, .slick-next {
  height: 30px;
  width: 30px;
}

.slick-prev {
  margin-left: -5px;
}

.slick-prev:before {
  background-image: url('/public/images/icon-home.png');
  background-position: 8px 6px;
  transform: rotate(-90deg);
  content: '';
  width: 40px;
  height: 40px;
  display: block;
  background-size: 60%;
  background-repeat: no-repeat;
  background-color: #3d3e42;
  border-radius: 20px;
}

.slick-next {
  margin-right: 5px;
}

.slick-next:before {
  background-image: url('/public/images/icon-home.png');
  background-position: 8px 6px;
  transform: rotate(90deg);
  content: '';
  width: 40px;
  height: 40px;
  display: block;
  background-size: 60%;
  background-repeat: no-repeat;
  background-color: #3d3e42;
  border-radius: 20px;
  margin-right: 3px;
}

.slick-prev:before,
.slick-next:before {
  color: #00aaf9;
  opacity: 1;
}

.slick-prev {
  left: -40px;
}

.slick-next {
  right: -40px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px;
}

.gallerySlideWrap {
  align-items: center;
  color: black;
  cursor: pointer;
  border-radius: 20px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.gallerySlide {
  align-items: center;
  background-color: #fff;
  border: 1px solid #fff;
  color: black;
  border-radius: 20px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: calc(100% - 20px);
  overflow: hidden;
}

.gallerySlideImage {
  flex: 1;
  width: 100%;
}

.gallerySlideContent {
  display: flex;
  flex: 0 1;
  padding: 10px 15px;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
}

.gallerySlideContent .gallerySlideIcon {
  border-radius: 20px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}

.gallerySlideContent h3 {
  font-size: 1rem;
  margin: 0;
  text-align: right;
}

.logo {
  top: 10px;
  width: 350px;
  height: 70px;
  left: 50%;
  z-index: 1;
}

/* Curved background container */
.screen-wallpaper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.curved-background-box {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

fieldset.screen-wallpaper-fieldset {
  /* border: 2px solid #fff; */
  border: 0;
  width: calc(100% - 10px);
  height: calc(100% - 20px);
  padding: 0;
  margin: 0;
}

fieldset.screen-wallpaper-fieldset legend {
  margin: 0 auto;
}

.curved-background-container.blur {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.curved-background-container {
  background-image: url('/public/images/background.jpg');
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.curved-background-container::before {
  content: "";
  position: absolute;
  width: 140%;
  height: 60vh;
  background-color: #000;
  top: -50% !important;
  left: -20% !important;
}

.curved-background-container.top::before {
  border-radius: 0 0 50% 50%;
}

.curved-background-container::after {
  content: "";
  position: absolute;
  width: 140%;
  height: 60vh;
  background-color: #000;
  bottom: -50% !important;
  left: -20% !important;
}

.curved-background-container.bottom::after {
  border-radius: 50% 50% 0 0;
}

.footer-info .logos {
  margin-bottom: 7%;
  position: relative;
  text-align: center;
}

.footer-info .logos img {
  height: 40px;
  margin-right: 10px;
}

.nfk-logo-container {
  position: absolute;
  bottom: 8%;
  left: 40px;
}

.nfk-logo-container img {
  background-color: #fff;
  height: 120px !important;
  padding: 10px;
}


.ButtonBack {
  margin-right: 10px;
}

.ButtonBack:before {
  content: '';
  background-image: url('/public/images/icon-home.png');
  background-position: 9px 9px;
  background-repeat: no-repeat;
  background-size: 60%;
  display: block;
  background-color: rgba(116, 116, 116, 0.5);
  text-align: center;
  border-radius: 25px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  transform: rotate(-90deg);
}

.ButtonNext {
  margin-right: 10px;
}

.ButtonNext:before {
  content: '';
  background-image: url('/public/images/icon-home.png');
  background-position: 10px 9px;
  background-repeat: no-repeat;
  background-size: 60%;
  display: block;
  background-color: rgba(116, 116, 116, 0.5);
  text-align: center;
  border-radius: 25px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  transform: rotate(90deg);
}

.InfoDiv {
  position: absolute;
  top: -1000px;
  left: -1000px;
  max-width: 300px;
}

.InfoDivHeader {
  background-color: #22211f;
  color: #cbb15c;
  font-weight: bold;
  padding: 10px;
  text-align: right;
  text-transform: uppercase;
}

.InfoDivContent {
  background-color: #000;
  color: white;
  font-size: 12px;
  padding: 10px;
  text-align: left;
}

.InfoDivContent ul {
  padding-left: 15px;
}

.InfoDivContent.left {
  text-align: right;
}

.InfoDivContent.right {
  text-align: left;
}
